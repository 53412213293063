// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Textfit } from 'react-textfit'
import { StaticQuery, graphql } from 'gatsby'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import TableOfContents from './toc'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img: file(relativePath: { eq: "cover.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const CoverPage = React.memo(({ lang }) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Fragment>
        <GatsbyImage className="cover flow" image={getImage(data.img)} />
        <p className="tj">
          This book presents most of Mother’s Matrimandir talks, including how
          she conceived the idea for this special concentration and meditation
          building in Auroville. Her talks about this subject were with the
          architect Roger Anger, various disciples from the Sri Aurobindo
          Ashram, and various Aurovilians. Her reflections on such a project
          started early as 1954, and the talks ended with her passing on 17th
          November 1973.
        </p>
        <TableOfContents lang={lang} />
      </Fragment>
    )}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default CoverPage
