// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import map from 'lodash/map'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import head from 'lodash/head'
import takeRight from 'lodash/takeRight'
import matches from 'lodash/matches'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources(sort: { order: ASC, fields: position }) {
      edges {
        node {
          title {
            content
            lang
          }
          position
          routeSlug
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const TOCRenderer = React.memo(({ nodes, nodePositions, update, lang }) => (
  <ul className="flow force homepage-table-of-contents">
    {map(nodePositions, (nodePosition) => {
      const hasChildren = nodePosition.length > 1
      const first = head(nodePosition, 1)
      const children =
        hasChildren === true
          ? takeRight(nodePosition, nodePosition.length - 1)
          : []

      return (
        <Fragment>
          {hasChildren === false && (
            <li>
              <Link
                to={filter(nodes, matches({ position: first }))[0].routeSlug}
                onClick={() => update(false, 'root')}
              >
                <span>
                  {filter(nodes, matches({ position: first }))[0].intlTitle}
                </span>
                <span>{nodePosition}</span>
              </Link>
            </li>
          )}
          {hasChildren === true && (
            <li>
              <Link
                to={filter(nodes, matches({ position: first }))[0].routeSlug}
                onClick={() => update(false, 'root')}
                className="block-heading"
              >
                <span>
                  {filter(nodes, matches({ position: first }))[0].intlTitle}
                </span>
                <span>{first}</span>
              </Link>
              <ul>
                {map(children, (child) => (
                  <li>
                    <Link
                      to={`${
                        filter(nodes, matches({ position: first }))[0].routeSlug
                      }#${
                        filter(nodes, matches({ position: child }))[0].routeSlug
                      }`}
                      onClick={() => update(false, 'root')}
                    >
                      <span>
                        {
                          filter(nodes, matches({ position: child }))[0]
                            .intlTitle
                        }
                      </span>
                      <span>{child}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          )}
        </Fragment>
      )
    })}
  </ul>
))

/** TableOfContents */
const TableOfContents = React.memo(({ update = () => true, lang }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const nodes = map(data.allResources.edges, 'node').slice(0, -1)
      const intlNodes = map(nodes, (node) => ({
        ...node,
        intlTitle: filter(node.title, ['lang', lang])[0].content,
      }))
      const nodePositions = groupBy(map(intlNodes, 'position'), Math.floor)

      return (
        <TOCRenderer
          nodes={intlNodes}
          nodePositions={nodePositions}
          update={update}
        />
      )
    }}
  />
))

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default TableOfContents
